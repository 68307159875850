import React,{useState,useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow,Dialog } from '@mui/material'
import { useSelector } from 'react-redux';
import PayoutPaymentMethodsHook from '../hooks/PayoutPaymentMethodsHook';
import { useAuthContext } from '../hooks/adminAuthHook';

export default function PayoutPaymentMethods() {
  const { admin } = useAuthContext();
  const apiUrl = process.env.REACT_APP_API_URL;
  const payoutPaymentMethods = useSelector((state) => state.payoutPaymentMethods.payoutPaymentMethods);
  const { getPayoutPaymentMethods } = PayoutPaymentMethodsHook(); 
  useEffect(() => {
    getPayoutPaymentMethods()
  }, [admin]);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

   // PaymentMethod Edition
   const [editedPaymentMethod,setEditedPaymentMethod]=useState(null)
   // Adding a new PaymentMethod
   const [payment_Gateway, setPayment_Gateway] = useState(""); 
   const [openNew, setOpenNew] = React.useState(false);
   const handleClickOpenNew = () => {
     setOpenNew(true);
   };
 
   const handleCloseNew = () => {
     setOpenNew(false);
     setPayment_Gateway('')
     setEditedPaymentMethod('')
   }
 
 const handleEditView=(data)=>{
   setEditedPaymentMethod(data);
   setOpenNew(true);
   setPayment_Gateway(data.payment_Gateway || "");
  }

   // API Call to add a new paymentMethod
   const [loading1, setLoading1] = useState(false)
   const addNewPaymentMethod = async (e) => {
     e.preventDefault()
     setLoading1(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/referrals/add/payment_method`, {
         method: "POST",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({payment_Gateway})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getPayoutPaymentMethods()
         setLoading1(null)
         handleCloseNew()
       }
       if (!response.ok) {
         alert(json.message)
         setLoading1(null)
       }
     } catch (error) {
       setLoading1(null)
     }
   }
 
   const updatePaymentMethod = async (e) => {
     e.preventDefault()
     setLoading1(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/referrals/update/payment_method`, {
         method: "PUT",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({paymentMethod:{_id:editedPaymentMethod._id,payment_Gateway}})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getPayoutPaymentMethods()
         setLoading1(null)
         handleCloseNew()
 
       }
       if (!response.ok) {
         alert(json.message)
         setLoading1(null)
       }
     } catch (error) {
       setLoading1(null)
     }
   }



    // Deleting 
  const [paymentMethodToBeDelete, setPaymentMethodToBeDelete] = useState()
  const [openDel, setOpenDel] = React.useState(false);
  const handleClickDelete = (request) => {
    setOpenDel(true);
    setPaymentMethodToBeDelete(request)
  }

  const handleCancelDelete = () => {
    setOpenDel(false)
    setTimeout(() => {
      setPaymentMethodToBeDelete('')
    }, 1000);
  }

  // Deleting Withdrawl request
const [delLoading,setDelLoading]=useState(false)
  const deletePaymentMethod=async()=>{
    setDelLoading(true)
    try {

      const response = await fetch(`${apiUrl}/auth/admins/referrals/delete/payment_method`, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({paymentMethodId:paymentMethodToBeDelete._id})
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        setDelLoading(false)
        setPaymentMethodToBeDelete('')
        handleCancelDelete()
        getPayoutPaymentMethods()
      }
      if (!response.ok) {
        alert(json.message)
        setDelLoading(null)
      }
      
    } catch (error) {
      setDelLoading(false)
      
    }
  }



   const [search, setSearch] = useState('');
   const filteredPaymentMethods = payoutPaymentMethods && payoutPaymentMethods
     .filter(newPaymentMethod => {
       const matchesSearch = (
         newPaymentMethod.payment_Gateway?.trim().toLowerCase().includes(search.trim().toLowerCase())
       );
 
       return matchesSearch
     })
     .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
     useEffect(() => {
      setPage(0)
    }, [search])
  return (
    <div className='row px-2'>
 <div className="col-md-12 data_table p-2 pb-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                      <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                        <h6>All Payout Payment Methods</h6>
                      </div>
                      <div className="d-flex right my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                        <button className='add_btn btn ms-1' onClick={() => handleClickOpenNew()}>Add</button>
                      </div>
                    </div>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                        <TableHead>
                          <TableRow>
                            <TableCell className='th text-center'>SN</TableCell>
                            <TableCell className='th text-center'>Payment Methods</TableCell>
                            <TableCell className='th text-center'>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredPaymentMethods && filteredPaymentMethods.length > 0 ? filteredPaymentMethods
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((newPaymentMethod, index) => (
                              <TableRow key={newPaymentMethod._id}>
                                <TableCell className='text-center td'>{index + 1}</TableCell>
                                <TableCell className='text-center td'>{newPaymentMethod.payment_Gateway}</TableCell> 
                                <TableCell className='td text-center'>
                                  <div class="btn-group" role="group" aria-label="Basic example">
                                    <button className="edit_btn py-1 px-2  m-1" onClick={()=>handleEditView(newPaymentMethod)}>Edit</button>
                                    <button className="inactivate_shops py-1 px-2  m-1" onClick={() => handleClickDelete(newPaymentMethod)}>Delete</button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )) :
                            <TableRow className='border-0'>
                              <TableCell colSpan={1}></TableCell>
                              <TableCell className='td text-center'>No result</TableCell>
                              <TableCell colSpan={1}></TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      component='div'
                      count={filteredPaymentMethods && filteredPaymentMethods.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      style={{
                        color: '#5932EA',
                        fontSize: '12px',
                        fontWeight: '700',
                        textTransform: 'capitalize',
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
              



{/* Deleting */}
<Dialog className='shops_modal p-0'
        open={openDel}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="alert_icon text-center">
                <div className="inactivate_icon  py-2">  
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
              </svg>
                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to delete the Payment Method ?</p>
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2"  onClick={handleCancelDelete}disabled={delLoading}>Cancel</button>
              <button className="inactivate m-1 py-1 px-2"onClick={deletePaymentMethod} disabled={delLoading}>{delLoading?"Deleting...":"Delete"}</button>
            </div>
          </div>
        </div>

      </Dialog>
 {/* adding a new Payment method */}

 <Dialog className='p-0'
        open={openNew}
    
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12  pb-2 px-0 m-0">
              <form onSubmit={editedPaymentMethod?updatePaymentMethod:addNewPaymentMethod}>
                <div className="card add_new_package_card">
                  <div className="card-header">
                    {editedPaymentMethod ? "Edit Payment Method" : "Add a new Payment Method"}
                    </div>
                  <div className="card-body">  
                    <div className="data my-1">
                      <label htmlFor="">Payment Method:</label>
                      <input type="text" className='mt-1' id="" value={payment_Gateway} onChange={(e) => setPayment_Gateway(e.target.value)} required/>
                    </div>
                  
                    <div className="text-end mt-3">
                      <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCloseNew()} disabled={loading1}>Cancel</button>
                      <button className="btn save_btn mx-1" type='submit' disabled={loading1}>{loading1 ? "Saving" : "Save"}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>

        
    </div>
  )
}
