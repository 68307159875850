import { useAuthContext } from './adminAuthHook'
import { useNavigate } from 'react-router-dom'

export default function LogoutHook() {
const {dispatch}=useAuthContext()
const navigate = useNavigate()

// learner logout
const adminLogout=()=>{
  if (window.confirm('Are you sure you want to Logout?')){
      setTimeout(() => {
        navigate("/")
        sessionStorage.removeItem('admin')
      }, 10);
      dispatch({ type: "ADMIN_LOGOUT" });
    
  }
   
    }
  return {adminLogout}
}
