import {useState,useRef} from 'react'
import { getAllPayoutPaymentMethods } from '../redux/reducers/payoutPaymentMethodsSlice'
import { useDispatch } from 'react-redux';
export default function PayoutPaymentMethodsHook() {
    const dispatch = useDispatch()
    const apiUrl = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(null)
    // Fetching Offers
    const abortCont = useRef(new AbortController());

    const getPayoutPaymentMethods=async()=>{
      setLoading(true)
       try {
        const response=await fetch(`${apiUrl}/auth/admins/referrals/get/payment_methods`,{
          signal: abortCont.current.signal
          
        })
        
        const json=await response.json()
        if(response.ok){
          setLoading(null)
          dispatch(getAllPayoutPaymentMethods(json.data))
        }
        if(!response.ok){
            setLoading(null)
        }
       } catch (error) {
       console.log(error)
       setLoading(null)
       }
    }

  return {getPayoutPaymentMethods,loading}
}
