import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    revenues: []
};

export const revenueSlice = createSlice({
    name: 'revenueSlice',
    initialState,
    reducers: {
        // 1- offers Reducers
        // a- getting  revenues
        getAllRevenue: (state, action) => {
            state.revenues = action.payload;
        },
      
    },
});

// Action creators are generated for each case reducer function
export const { getAllRevenue} = revenueSlice.actions;

export default revenueSlice.reducer;
