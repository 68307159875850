import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    transactions: []
};

export const transactionsSlice = createSlice({
    name: 'transactionsSlice',
    initialState,
    reducers: {
        // 1- Transactions Reducers
        // a- getting get Transactions
        getAllTransactions: (state, action) => {
            state.transactions = action.payload;
        },
        // b- updating a Transactions
        updateTransaction: (state, action) => {
            const data = action.payload; // Assuming action.payload is the transaction object
            const index = state.transactions.findIndex(transaction => transaction._id === data._id);
            if (index !== -1) {
                state.transactions[index] = { ...state.transactions[index], ...data };
            }
        }
    },
});

// Action creators are generated for each case reducer function
export const { getAllTransactions, updateTransaction } = transactionsSlice.actions;

export default transactionsSlice.reducer;
