import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    paymentMethods: []
};

export const paymentMethodsSlice = createSlice({
    name: 'paymentMethodsSlice',
    initialState,
    reducers: {
        // 1- offers Reducers
        // a- getting for Offers
        getAllPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload;
        },
      
    },
});

// Action creators are generated for each case reducer function
export const { getAllPaymentMethods} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
