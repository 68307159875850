import React, { useState, useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow, Menu, MenuItem, Skeleton, Box, Dialog, TextField } from '@mui/material'
import { useSelector } from 'react-redux';
import HandleShopsHook from '../hooks/HandleShopsHook'
import { useAuthContext } from '../hooks/adminAuthHook';
import { formatDistanceToNow } from 'date-fns/formatDistanceToNow'
import TransactionsHook from '../hooks/TransactionsHook'

export default function Statistics() {

  const { admin } = useAuthContext();

  const {getShops} = HandleShopsHook()
  const { getTransactions } = TransactionsHook()

  useEffect(() => {
    document.title = "Statistics";
    getShops();
    getTransactions()
  }, [admin]); 

  const shops = useSelector((state) => state.shops.shops)
  const transactions = useSelector((state) => state.transactions.transactions)

  const collapsed = useSelector((state) => state.collapsed.collapsed);

  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState('')

  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')

// Getting filtered registered shops
const today = new Date();
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

const isWithinFilter = (shops) => {
  const shopslastInteraction = new Date(shops.lastInteraction);
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  // Ensure date strings are converted to Date objects and are valid
  const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

  if (filterValue === 'today') {
    return shopslastInteraction.toDateString() === today.toDateString();
  } else if (filterValue === 'thisWeek') {
    return shopslastInteraction >= sevenDaysAgo && shopslastInteraction <= today;
  } else if (filterValue === 'custom') {
    // Handle empty strings gracefully
    const fromDate = dateFrom ? new Date(dateFrom) : undefined;
    const toDate = dateTo ? new Date(dateTo) : undefined;
    if (!isValidDate(fromDate) || !isValidDate(toDate)) {
    
      return false;
    }

    // Check if shopsCreatedAt is within the custom date range
    return shopslastInteraction >= fromDate && shopslastInteraction <= toDate;
  } else {
    return true; // 'all' case or any other default case
  }
};

const filteredShops = shops && shops
  .filter(shops => {
    const matchesSearch = (
      shops.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      shops.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      shops.phone?.trim().includes(search.trim()) ||
      shops.customers?.toString().includes(search) ||
      shops.createdAt?.includes(search.trim()) ||
      shops.orders?.toString().includes(search) ||
      (shops.isActive ? 'active' : 'inactive').includes(search.trim().toLowerCase())
    );
    return matchesSearch && isWithinFilter(shops);
  })
  .sort((a, b) => (b.interactionCount) - (a.interactionCount));
  // filter menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  };

  const menuItemStyles = {
    padding: '4px 12px',
    fontSize: '12px',
    color: '#000',
    cursor: 'pointer'
  };

  const menuItemHoverStyles = {
    backgroundColor: '#f5f5f5'
  };

  // custom date range filter
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  }

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  useEffect(() => {
    setPage(0)
  }, [search,dateFrom,dateTo,filterValue])
  // Sorting Functionalty
  const [sortConfig, setSortConfig] = useState({ key: 'interactionCount', direction: '' });
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };
  
  const sortedShops = [...filteredShops].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key] || '';
      const bValue = b[sortConfig.key] || '';
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
  
  return (
    <div className={`${collapsed ? "collapsed dashboard mt-5 " : "main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-2">
          <div className="col-md-6 col-lg-3 p-2">
            <div className="card orders_received p-0 m-0">
              <div className="card-body px-0 pb-1">
                <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                  <div className=" left">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                    </svg>
                    <h6 className='ms-2'>Total Shops</h6>
                  </div>
                  <div className="right">
                    <p>
                    {shops && (shops.length < 10 ? "0" + shops.length : shops.length)}
                     
                    </p>
                  </div>
                </div>
                <hr className='px-0 pb-0 mb-2' />
                <div className="d-flex  px-3 pb-0 pt-0">
                  <p> {shops && (() => {
                          const inActiveShopsCount = shops.filter(shops => !shops.isActive).length;
                          return inActiveShopsCount < 10 ? "0" + inActiveShopsCount.toLocaleString() : inActiveShopsCount.toLocaleString();
                        })()} Inactive Shops</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 p-2">
                <div className="card top_shops">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                      </svg>

                        <h6 className='ms-2'>Paid Shops</h6>
                      </div>
                      <div className="right">
                        <p>
                        {shops && (shops.filter(shop=>shop.payment_Status.toLowerCase()==='paid').length < 10 ? "0" + shops.filter(shop=>shop.payment_Status.toLowerCase()==='paid').length : shops.filter(shop=>shop.payment_Status.toLowerCase()==='paid').length.toLocaleString())}
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p> {shops && (shops.filter(shop=>shop.payment_Status.toLowerCase()==='trial').length < 10 ? "0" + shops.filter(shop=>shop.payment_Status.toLowerCase()==='trial').length : shops.filter(shop=>shop.payment_Status.toLowerCase()==='trial').length.toLocaleString())} Shops using free trial</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 p-2">
                <div className="card inactive_shops">

                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2 ">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <h6 className='ms-2'>Plans Expired</h6>
                      </div>
                      <div className="right">
                        <p>
                        {shops && (shops.filter(shop=>shop.payment_Status.toLowerCase()==='unpaid').length < 10 ? "0" + shops.filter(shop=>shop.payment_Status.toLowerCase()==='unpaid').length : shops.filter(shop=>shop.payment_Status.toLowerCase()==='unpaid').length.toLocaleString())}
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>{shops && ((shops.filter(shop=>shop.payment_Status.toLowerCase()==='paid').length+shops.filter(shop=>shop.payment_Status.toLowerCase()==='trial').length)?.toLocaleString())} Eligible Shops to use app features</p>
                    </div>
                  </div>
                </div>
              </div>
                <div className="col-md-6 col-lg-3 p-2">
                <div className="card orders_processing">
                  <div className="card-body px-0 pb-1">
                    <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2">
                      <div className=" left">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 fa-spin">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                        <h6 className='ms-2'>Pending Subscriptions</h6>
                      </div>
                      <div className="right">
                        <p>
                        {transactions && (transactions.filter(transaction=>transaction.status.toLowerCase()==='pending').length < 10 ? "0" + transactions.filter(transaction=>transaction.status.toLowerCase()==='pending').length : transactions.filter(transaction=>transaction.status.toLowerCase()==='pending').length.toLocaleString())}
                        </p>
                      </div>
                    </div>
                    <hr className='px-0 pb-0 mb-2' />
                    <div className="d-flex  px-3 pb-0 pt-0">
                    <p>{shops && (shops.filter(shop=>shop.payment_Status.toLowerCase()==='pending').length < 10 ? "0" + shops.filter(shop=>shop.payment_Status.toLowerCase()==='pending').length : shops.filter(shop=>shop.payment_Status.toLowerCase()==='pending').length.toLocaleString())} Transactions need instant lookup</p>
                    </div>
                  </div>
                </div>
              </div>
        </div>

        {/* Table details */}
        <div className="row px-3 mt-md-4">
         
            <div className="col-md-12 data_table p-2 pb-5">
              <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                {/* Left section */}
                <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                  <h6>
                    Frequently active shops
                    <span>{dateFrom && dateTo && `( ${dateFrom} to ${dateTo} )`}</span>
                  </h6>
                </div>
                
                <div className="d-flex right my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                  <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search.." />

                  {/* Filters button */}
                  <button className="filter_btn" id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                  </button>

                  {/* Menu for filter options */}
                  <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', style: menuStyles }}>
                    <MenuItem onClick={() => { handleClose(); setFilterValue(''); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
                      All
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); setFilterValue('today'); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
                      Today
                    </MenuItem>
                    <MenuItem onClick={() => { handleClose(); setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
                      This Week
                    </MenuItem>
                    <MenuItem onClick={() => { handleClickOpenModal(); setFilterValue('custom') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
                      Custom Date
                    </MenuItem>
                    <MenuItem onClick={() => { setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }} style={menuItemStyles} onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor} onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}>
                      Default
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                  <TableHead>
                  <TableRow>
                  <TableCell className='th text-center'>SN</TableCell>
                  <TableCell
                    className='th text-center sortable'
                    onClick={() => handleSort('name')}
                  >
                    Shops {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? <i className="ms-1 fa-solid fa-arrow-up"></i> : <i className="ms-1 fa-solid fa-arrow-down"></i>)}
                  </TableCell>
                  <TableCell className='th text-center'>Phone</TableCell>
                  <TableCell
                    className='th text-center sortable'
                    onClick={() => handleSort('customers')}
                  >
                    Customers {sortConfig.key === 'customers' && (sortConfig.direction === 'asc' ? <i className="ms-1 fa-solid fa-arrow-up"></i> : <i className="ms-1 fa-solid fa-arrow-down"></i>)}
                  </TableCell>
                  <TableCell
                    className='th text-center sortable'
                    onClick={() => handleSort('orders')}
                  >
                    Orders {sortConfig.key === 'orders' && (sortConfig.direction === 'asc' ? <i className="ms-1 fa-solid fa-arrow-up"></i> : <i className="ms-1 fa-solid fa-arrow-down"></i>)}
                  </TableCell>
                  <TableCell
                    className='th text-center sortable'
                    onClick={() => handleSort('interactionCount')}
                  >
                    Visits {sortConfig.key === 'interactionCount' && (sortConfig.direction === 'asc' ? <i className="ms-1 fa-solid fa-arrow-up"></i> : <i className="ms-1 fa-solid fa-arrow-down"></i>)}
                  </TableCell>
                  <TableCell
                    className='th text-center sortable'
                    onClick={() => handleSort('lastInteraction')}
                  >
                    Last Visit {sortConfig.key === 'lastInteraction' && (sortConfig.direction === 'asc' ? <i className="ms-1 fa-solid fa-arrow-up"></i> : <i className="ms-1 fa-solid fa-arrow-down"></i>)}
                  </TableCell>
                  <TableCell className='th text-center'>Status</TableCell>
                </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedShops && sortedShops.length > 0 ? sortedShops
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((shop, index) => (
                        <TableRow key={index}>
                          <TableCell className='td text-center'>{index + 1}</TableCell>
                          {/* <TableCell className='td text-center'>{formatDistanceToNow(new Date(shop.createdAt), { addSuffix: true })}</TableCell> */}
                          <TableCell className='td text-center'>{shop.name}</TableCell>
                          <TableCell className='td text-center'><a href={`https://wa.me/+92${shop.phone}`} target="_blank" rel="noreferrer">{shop.phone}</a></TableCell>
                          <TableCell className='td text-center'>{shop.customers}</TableCell>
                          <TableCell className='td text-center'>{shop.orders}</TableCell>
                          {/* <TableCell className='td text-center '><span className='revenue_span p-1 rounded'>{shop.orders && shop.orders
                            .filter(order => order.order_Status?.trim().toLowerCase() === 'delivered')
                            .reduce((sum, order) => sum + (order.paid_Amount || 0), 0).toLocaleString()} PKR</span></TableCell>
                          <TableCell className='td text-center'>{shop.customers.length}</TableCell>
                          <TableCell className='td text-center'>{shop.orders.length}</TableCell> */}
                          {/* <TableCell className='td text-center'>{shop.payment_Status?.toLowerCase() === 'paid' ? <span className="paid px-2 py-1">Paid</span> : shop.payment_Status?.toLowerCase() === 'trial' ? <span className="trial px-2 py-1">Trial</span> : <span className="unpaid px-2 py-1">Unpaid</span>}</TableCell> */}
                          <TableCell className='td text-center'>{shop.interactionCount}</TableCell>
                          <TableCell className='td text-center'>{shop.lastInteraction}</TableCell>
                          <TableCell className='td text-center'>{shop.isActive ? <span className="active px-2 py-1">Active</span> : <span className="unactive px-2 py-1">Inactive</span>}</TableCell>

                        </TableRow>
                      )) :
                      <TableRow className='border-0'>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        {/* <TableCell></TableCell> */}
                        {/* <TableCell></TableCell> */}
                        <TableCell className='td text-center'>No result</TableCell>
                        {/* <TableCell></TableCell> */}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={filteredShops && filteredShops.length}
                rowsPerPage={rowsPerPage}
                page={page}
                style={{
                  color: '#5932EA',
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'capitalize',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
        </div>
      </div>

      {/* Custom Date Range Modal */}
      <Dialog className='custom_date_modal p-0'
        open={openModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="content px-4 text-center">
                <h6 className='my-3 '>Custom filter!</h6>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      id="date-from"
                      label="Date From"
                      type="date"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                    <TextField
                      id="date-to"
                      label="Date To"
                      type="date"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                      InputLabelProps={{
                        shrink: true,  // Ensures label doesn't overlap with date input
                      }}
                      sx={{
                        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                      }}
                    />
                  </div>
                </Box>
              </div>
              <button className="cancel m-1 py-1 px-2" onClick={() => { handleCloseModal(); setDateFrom(''); setDateTo(''); setFilterValue('') }}>Cancel</button>
              <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  )
}
