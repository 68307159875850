import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    payoutrequests: []
};

export const withdrawlRequestsSlice = createSlice({
    name: 'withdrawlRequestsSlice',
    initialState,
    reducers: {
        // 1- offers Reducers
        // a- getting payouts
        getAllWithdrawlRequests: (state, action) => {
            state.payoutrequests = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getAllWithdrawlRequests} = withdrawlRequestsSlice.actions;

export default withdrawlRequestsSlice.reducer;
