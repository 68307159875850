import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow,Dialog } from '@mui/material'
import { useSelector } from 'react-redux';
import PayoutsHook from '../hooks/PayoutsHook';
import ReferralsHook from '../hooks/ReferralsHook';
import PayoutPaymentMethodsHook from '../hooks/PayoutPaymentMethodsHook';
import { useAuthContext } from '../hooks/adminAuthHook'
export default function ReferralsPayouts() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const { admin } = useAuthContext();
  const payouts = useSelector((state) => state.payouts.payouts);
  const referrals = useSelector((state) => state.referrals.referrals);
  const payoutPaymentMethods = useSelector((state) => state.payoutPaymentMethods.payoutPaymentMethods);
  const { getReferrals } = ReferralsHook(); 
  const { getPayouts } = PayoutsHook();
  const { getPayoutPaymentMethods } = PayoutPaymentMethodsHook(); 
  useEffect(() => {
    getPayouts()
    getReferrals()
    getPayoutPaymentMethods()
  }, [admin]);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [search, setSearch] = useState('');
  const filteredRequests = payouts && payouts
    .filter(payout => {
      const matchesSearch = (
        payout.phone?.trim().toLowerCase().includes(search.trim().toLowerCase())
      );

      return matchesSearch
    })
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const [openNew, setOpenNew] = useState(false);
// adding new Payout
const [phone, setPhone] = useState("");
const [amount, setAmount] = useState("");
const [payment_Method, setPayment_Method]=useState("");
const [slip, setSlip] = useState("");

const handleImage = (e) => {
  const file = e.target.files[0];
  TransformFile(file)
  if (file) {
      if (file.size > 5 * 1024 * 1024) {
          alert('File size exceeds the 5MB limit. Please select a smaller file.');
      } else {
          TransformFile(file);
      }
  }
};

const TransformFile = (file) => {
  const reader = new FileReader();
  if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setSlip(reader.result);
      };
  } else {
    setSlip('');
  }
};

  const handleNewPayout = () => {
    setOpenNew(true)
  }

  const handleCancel=()=>{
    setOpenNew(false)
    setPhone('')
    setAmount('')
    setPayment_Method('')
    setSlip('')
  }


  const [uploadingLoading,setUploadLoading]=useState(false)
  const addNewPayout=async(e)=>{
    e.preventDefault()
    setUploadLoading(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/referrals/add/payout`, {
         method: "POST",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({phone,amount,payment_Method,slip})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getPayouts()
         setOpenNew(false)
         setUploadLoading(null)
         handleCancel()
       }
       if (!response.ok) {
         alert(json.message)
         setUploadLoading(false)
       }
     } catch (error) {
      setUploadLoading(false)
     }
  }

  useEffect(() => {
    setPage(0)
  }, [search])

  return (
    <div className='row px-2'>
      <div className="col-md-12 data_table p-2 pb-5">
        <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
          <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
            <h6>All Payouts</h6>
          </div>
          <div className="d-flex right my-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
            <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
            <button className='add_btn btn ms-1' onClick={() => handleNewPayout()}>Add</button>
          </div>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
            <TableHead>
              <TableRow>
                <TableCell className='th text-center'>SN</TableCell>
                <TableCell className='th text-center'>Date</TableCell>
                <TableCell className='th text-center'>Referrals</TableCell>
                <TableCell className='th text-center'>Payment Method</TableCell>
                <TableCell className='th text-center'>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRequests && filteredRequests.length > 0 ? filteredRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((payout, index) => (
                  <TableRow key={payout._id}>
                    <TableCell className='text-center td'>{index + 1}</TableCell>
                    <TableCell className='text-center td'>{new Date(payout.createdAt).toLocaleDateString('en-GB').split('-').join('')}</TableCell>
                    <TableCell className='text-center td'>{payout.phone}</TableCell>
                    <TableCell className='text-center td'>{payout.payment_Method}</TableCell>
                    <TableCell className='text-center td'>{payout.amount.toLocaleString()} PKR</TableCell>
                  </TableRow>
                )) :
                <TableRow className='border-0'>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell className='td text-center'>No result</TableCell>
                  <TableCell colSpan={3}></TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={filteredRequests && filteredRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          style={{
            color: '#5932EA',
            fontSize: '12px',
            fontWeight: '700',
            textTransform: 'capitalize',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    
      
 {/* approving withdrawls payout moda; */}

 <Dialog className='p-0'
        open={openNew}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12  pb-2 px-0 m-0">
              <form onSubmit={addNewPayout}>
                <div className="card add_new_package_card">
                  <div className="card-header">
                    Add new Payout
                    </div>
                  <div className="card-body">
                  
                    <div className="image-container text-center data my-1">
                        <div className="image-wrapper">
                            <img
                                src={slip ? slip : "https://via.placeholder.com/100"}
                                alt="team-member"
                                className="profile-img"
                                onClick={() => document.getElementById('fileInput').click()}
                            />
                            <span className="add-icon" onClick={() => document.getElementById('fileInput').click()}>
                                +
                            </span>
                        </div>
                        <input
                            type="file"
                            accept='image/*'
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleImage}
                        />
                    </div>
                    <div className="data my-1">
                      <label htmlFor="" >Referral:</label>
                      <select name="" id="" value={phone} onChange={(e)=>setPhone(e.target.value)} className='mt-1'required >
                        <option value="">Choose</option>
                        {referrals&&referrals.map((data,index)=>(
                        <option value={data.phone}>{data.phone}</option>
                        ))}
                      </select>
                     
                    </div>
                  
                    <div className="data my-1">
                      <label htmlFor="">Amount:</label>
                      <input type="number" className='mt-1' id="" value={amount} onChange={(e) => setAmount(e.target.value)} required/>
                    </div>
                    <div className="data my-1">
                      <label htmlFor="">Payment Method</label>
                      <select name="" id=""  value={payment_Method} onChange={(e) => setPayment_Method(e.target.value)} className='mt-1'required>
                        <option value="">Select</option>
                        {payoutPaymentMethods&&payoutPaymentMethods.length>0?payoutPaymentMethods.map((data,index)=>(
                        <option key={index} value={data.payment_Gateway}>{data.payment_Gateway}</option>

                        )):
                        <option value="Jazz Cash">No payment method avilable</option>
                        }
                      </select>
                    </div>
                   
                    <div className="text-end mt-3">
                      <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCancel()} disabled={uploadingLoading}>Cancel</button>
                      <button className="btn save_btn mx-1" type='submit' disabled={uploadingLoading}>{uploadingLoading ? "Paying..." : "Payout"}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
