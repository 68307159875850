import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customers: [],
    totalcustomers: [],
}
export const customersSlice = createSlice({
    name: 'customersSlice',
    initialState,
    reducers: {
        // 1- Customers Reducers
        // a- getting get customers
        getAllCustomers: (state, action) => {
            state.customers = action.payload;
        },
        getTotalCustomersLength: (state, action) => {
            state.totalcustomers = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getAllCustomers,getTotalCustomersLength } = customersSlice.actions;

export default customersSlice.reducer;
