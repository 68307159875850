import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    payouts: []
};

export const payoutsSlice = createSlice({
    name: 'payoutsSlice',
    initialState,
    reducers: {
        // 1- offers Reducers
        // a- getting payouts
        getAllPayouts: (state, action) => {
            state.payouts = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getAllPayouts} = payoutsSlice.actions;

export default payoutsSlice.reducer;
