import { createContext, useReducer, useEffect } from 'react'

export const AuthSlice = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        // Cases for User
        case 'ADMIN_LOGIN':
            return {

                admin: action.payload
            }
        case 'ADMIN_LOGOUT':
            return {
                admin: null
            }

        default:
            return {
                state: null
            }

    }
}
export const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, {
        admin: null,

    })
    useEffect(() => {

        const admin = JSON.parse(sessionStorage.getItem('admin'))

        if (admin) {
            dispatch({ type: 'ADMIN_LOGIN', payload: admin })
        }


    }, [])

    return (
        <AuthSlice.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthSlice.Provider>
    )
}
