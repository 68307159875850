import './App.css';
import React, { useEffect, useState } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { useAuthContext } from './hooks/adminAuthHook';
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import Login from'./components/Login'
import Sidebar from'./components/Sidebar'
import Navbar from './components/Navbar.jsx'
import Dashboard from './components/Dashbaord'
import Orders from './components/Orders'
import Shops from './components/Shops.jsx'
import Customers from './components/Customers.jsx'
import Transactions from './components/Transactions.jsx'
import PlanAndPackages from './components/Plan&Packages.jsx'
import Offers from './components/Offers.jsx'
import Revenue from './components/Revenue.jsx'
import Reports from './components/Reports.jsx'
import AlertNotifications from './components/AlertNotifications.jsx'
import Referrals from './components/Referrals.jsx'
import Setting from './components/Setting.jsx'
import Statistics from './components/Statistics.jsx'

import { useSelector } from 'react-redux';
import AdminProfileHook from './hooks/AdminProfileHook.js';

function App() {

  const { getAdmin } = AdminProfileHook();
const { admin } = useAuthContext();
const adminProfile = useSelector((state) => state.adminProfile.adminProfile);

const [loading, setLoading] = useState(true);
useEffect(() => {
  if (adminProfile !== undefined) {
    setLoading(false);  
  }
}, [admin]);
if (loading) {
  return <div className='mt-5 '>
<MoonLoader color="#5932EA" className='mt-5 mx-auto'/>
  </div>;  
}
  return (
    <div className="App">
      <BrowserRouter>
      {admin && <Navbar></Navbar>}
      {admin && <Sidebar></Sidebar>}
          <Routes>
            <Route path="/" element={admin?<Dashboard></Dashboard>:<Login></Login>} />
            <Route path="/tailorbhai/super/admin/orders" element={adminProfile?<Orders></Orders>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/shops" element={adminProfile ?<Shops></Shops>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/customers" element={adminProfile ?<Customers></Customers>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/transactions" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<Transactions></Transactions>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/plans&packages" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<PlanAndPackages></PlanAndPackages>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/offers" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<Offers></Offers>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/revenue" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<Revenue></Revenue>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/reports" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<Reports></Reports>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/notifications" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<AlertNotifications></AlertNotifications>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/referrals" element={adminProfile ?<Referrals></Referrals>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/setting" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<Setting></Setting>: <Navigate to="/"/>} />
            <Route path="/tailorbhai/super/admin/statistics" element={adminProfile&&adminProfile?.role?.trim().toLowerCase()==='super admin' ?<Statistics></Statistics>: <Navigate to="/"/>} />

          <Route path="*" element={<Navigate to="/" />} />
           </Routes>      
      </BrowserRouter>

    </div>
  );
}

export default App;
