import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    referrals: []
};

export const referralsSlice = createSlice({
    name: 'referralsSlice',
    initialState,
    reducers: {
        // 1- offers Reducers
        // a- getting Referrals
        getAllReferrals: (state, action) => {
            state.referrals = action.payload;
        },
      
    },
});

// Action creators are generated for each case reducer function
export const { getAllReferrals} = referralsSlice.actions;

export default referralsSlice.reducer;
