import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer,TablePagination, TableHead, TableRow, Dialog, Box, Skeleton, Collapse, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import CustomersHook from '../hooks/CustomersHook'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
export default function Customers() {
    const { loading, getCustomers } = CustomersHook()
    useEffect(() => {
    document.title = 'Customers';
    getCustomers()
    }, [])
    const customers = useSelector((state) => state.customers.customers)

    const [search, setSearch] = useState('');
    const [filterValue, setFilterValue] = useState('')
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
    // Getting filtered registered shops
    const today = new Date();
    // Define options for formatting the date
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
  
    // Get the formatted date string
    const formattedDate = today.toLocaleDateString('en-US', options);

    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const isWithinFilter = (customer) => {
        const customerCreatedAt = new Date(customer.createdAt);
        const today = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 7);

        // Ensure date strings are converted to Date objects and are valid
        const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

        if (filterValue === 'today') {
            return customerCreatedAt.toDateString() === today.toDateString();
        } else if (filterValue === 'thisWeek') {
            return customerCreatedAt >= sevenDaysAgo && customerCreatedAt <= today;
        } else if (filterValue === 'custom') {
            // Handle empty strings gracefully
            const fromDate = dateFrom ? new Date(dateFrom) : undefined;
            const toDate = dateTo ? new Date(dateTo) : undefined;
            if (!isValidDate(fromDate) || !isValidDate(toDate)) {

                return false;
            }

            // Check if customerCreatedAt is within the custom date range
            return customerCreatedAt >= fromDate && customerCreatedAt <= toDate;
        } else {
            return true; // 'all' case or any other default case
        }
    };

    const filteredCustomers = customers && customers
        .filter(customer => {
            const matchesSearch = (
                customer.customer_Name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
                customer.phone?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
                customer.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
                customer.address?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
                customer.shopName?.trim()?.toLowerCase().includes(search.trim().toLowerCase()) ||
                customer.shopPhone?.trim()?.toLowerCase().includes(search.trim().toLowerCase()) ||
                customer.gender?.trim().toLowerCase().includes(search.trim().toLowerCase())
            )
            return matchesSearch && isWithinFilter(customer);
        })
        ?.sort((a, b) => {
            const valueA = a[sortConfig.key];
            const valueB = b[sortConfig.key];
            if (sortConfig.direction === 'asc') {
              return valueA > valueB ? 1 : -1;
            }
            return valueA < valueB ? 1 : -1;
          });
      
        const handleSort = (key) => {
          setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
          }));
        };

        console.log('filteredCustomers',filteredCustomers)

  // Finding the customer with the most orders
const maxOrdersCustomer = customers && customers.reduce((max, customer) => {
    return (customer.orders.length > max.orders.length) ? customer : max;
}, customers[0] || { orders: [] }); // Initialize with the first customer
    // View Details
    const [openDetails, setOpenDetails] = useState(null);
    const toggleOpen = (customerId) => {
        setOpenDetails((prevCustomerId) => (prevCustomerId === customerId ? null : customerId));
    };

    // filter menu

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuStyles = {
        borderRadius: '4px',
        backgroundColor: '#fff',
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
    };

    const menuItemStyles = {
        padding: '4px 12px',
        fontSize: '12px',
        color: '#000',
        cursor: 'pointer'
    };

    const menuItemHoverStyles = {
        backgroundColor: '#f5f5f5'
    };

    // custom date range filter
    const [openModal, setOpenModal] = useState(false);
    const handleClickOpenModal = (tailor) => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);

    }

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0)
  }, [search,dateFrom,dateTo,filterValue])
  

  const collapsed = useSelector((state) => state.collapsed.collapsed);

    return (
        <div className={`${collapsed ?"collapsed dashboard mt-5":"main dashboard mt-5"}`}>
            <div className="container-fluid bg-none my-0 mt-4">
                <div className="row px-2">
                    
                            <div className="col-md-6 col-lg-3 p-2">
                                <div className="card orders_received p-0 m-0">
                                    <div className="card-body px-0 pb-1">
                                        <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2 ">
                                            <div className=" left">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                                                </svg>
                                                <h6 className='ms-2'>Total Customers</h6>
                                            </div>
                                            <div className="right">
                                            <p>
                                            {customers && (customers.length < 10 ? "0" + customers.length : customers.length)}
                                            </p>
                                            </div>
                                        </div>
                                        <hr className='px-0 pb-0 mb-2' />
                                        <div className="d-flex  px-3 pb-0 pt-0">
                                        <p>Total number of customers palcing orders</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-6 col-lg-3 p-2">
                                <div className="card orders_delivered">

                                    <div className="card-body px-0 pb-1">
                                        <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2 ">
                                            <div className=" left">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                                <h6 className='ms-2'>Active Customers</h6>
                                            </div>
                                            <div className="right">
                                                <p>
                                                {customers && (customers.length < 10 ? "0" + customers.length : customers.length)}
                                                </p>
                                            </div>
                                        </div>
                                        <hr className='px-0 pb-0 mb-2' />
                                        <div className="d-flex  px-3 pb-0 pt-0">
                                        <p>Updated at {formattedDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-3 p-2">
                                <div className="card inactive_shops">
                                    <div className="card-body px-0 pb-1">
                                        <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2 ">
                                            <div className=" left">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                </svg>
                                                <h6 className='ms-2'>Inactive Customers</h6>
                                            </div>
                                            <div className="right">
                                                <p>
                                                   00
                                                </p>
                                            </div>
                                        </div>
                                        <hr className='px-0 pb-0 mb-2' />
                                        <div className="d-flex  px-3 pb-0 pt-0">
                                        <p>Updated at {formattedDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 p-2">
                                <div className="card top_shops">

                                    <div className="card-body px-0 pb-1">
                                        <div className="d-flex justify-content-between top_content px-3 mb-2 mt-2 ">
                                            <div className=" left">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                                                </svg>
                                                <h6 className='ms-2'>Top Customer</h6>
                                            </div>
                                            <div className="right">
                                            <p>
                                            {maxOrdersCustomer.customer_Name &&
                                            (maxOrdersCustomer.customer_Name.length > 8
                                                ? maxOrdersCustomer.customer_Name.substring(0, 8) + '...'
                                                : maxOrdersCustomer.customer_Name.charAt(0).toUpperCase() +
                                                maxOrdersCustomer.customer_Name.slice(1).toLowerCase())}

                                            </p>

                                            </div>
                                        </div>
                                        <hr className='px-0 pb-0 mb-2' />
                                        <div className="d-flex  px-3 pb-0 pt-0">
                                        <p>Updated at {formattedDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                </div>

                <div className="row px-3 mt-md-4">
                    {loading && (customers && customers.length < 1) ?
                        <>

                            <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                            <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                            <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                            <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
                        </>
                        :
                        <div className="col-md-12 data_table p-2 pb-5">
                            <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                                <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                                    <h6>{filterValue === 'thisWeek' ? "This week new" : filterValue === 'today' ? "Today new" : filterValue === 'custom' ? "Custom date" : "All"} Customers <span>{dateFrom && dateTo && `( ${dateFrom} to ${dateTo} )`}</span></h6>
                                </div>
                                <div className="d-flex right my-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                    </svg>
                                    <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />

                                    {/* filters */}
                                    <button
                                        className='filter_btn'
                                        id="basic-button"
                                        aria-controls={openMenu ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}
                                        onClick={handleMenuClick}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                                        </svg>

                                    </button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={openMenu}
                                        onClose={handleMenuClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            style: menuStyles,
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => { handleMenuClose(); setFilterValue('');; setDateFrom(''); setDateTo('') }}
                                            style={menuItemStyles}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                                        >
                                            All
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => { handleMenuClose(); setFilterValue('today');; setDateFrom(''); setDateTo('') }}
                                            style={menuItemStyles}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                                        >
                                            Today
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => { handleMenuClose(); setFilterValue('thisWeek');; setDateFrom(''); setDateTo('') }}
                                            style={menuItemStyles}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                                        >
                                            This Week
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => { handleClickOpenModal(); setFilterValue('custom') }}
                                            style={menuItemStyles}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                                        >
                                            Custom Date
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => { setFilterValue(''); setDateFrom(''); setDateTo('') }}
                                            style={menuItemStyles}
                                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                                        >
                                            Default
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center' stickyHeader>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell className="th text-center">
                                            #
                                        </TableCell>
                                        <TableCell className="th text-center" onClick={() => handleSort('customer_Name')}>
                                            Name
                                            {sortConfig.key === 'customer_Name' && (
                                            sortConfig.direction === 'asc' ? (
                                                <i className="ms-1 fa-solid fa-arrow-up"></i>
                                            ) : (
                                                <i className="ms-1 fa-solid fa-arrow-down"></i>
                                            )
                                            )}
                                        </TableCell>
                                        <TableCell className="th text-center" onClick={() => handleSort('email')}>
                                            Email
                                            {sortConfig.key === 'email' && (
                                            sortConfig.direction === 'asc' ? (
                                                <i className="ms-1 fa-solid fa-arrow-up"></i>
                                            ) : (
                                                <i className="ms-1 fa-solid fa-arrow-down"></i>
                                            )
                                            )}
                                        </TableCell>
                                        <TableCell className="th text-center" onClick={() => handleSort('phone')}>
                                            Phone
                                            {sortConfig.key === 'phone' && (
                                            sortConfig.direction === 'asc' ? (
                                                <i className="ms-1 fa-solid fa-arrow-up"></i>
                                            ) : (
                                                <i className="ms-1 fa-solid fa-arrow-down"></i>
                                            )
                                            )}
                                        </TableCell>
                                        <TableCell className="th text-center" onClick={() => handleSort('gender')}>
                                            Gender
                                            {sortConfig.key === 'gender' && (
                                            sortConfig.direction === 'asc' ? (
                                                <i className="ms-1 fa-solid fa-arrow-up"></i>
                                            ) : (
                                                <i className="ms-1 fa-solid fa-arrow-down"></i>
                                            )
                                            )}
                                        </TableCell>
                                        <TableCell className="th text-center" onClick={() => handleSort('address')}>
                                            Address
                                            {sortConfig.key === 'address' && (
                                            sortConfig.direction === 'asc' ? (
                                                <i className="ms-1 fa-solid fa-arrow-up"></i>
                                            ) : (
                                                <i className="ms-1 fa-solid fa-arrow-down"></i>
                                            )
                                            )}
                                        </TableCell>
                                        <TableCell className="th text-center" onClick={() => handleSort('shopName')}>
                                            Shops {sortConfig.key === 'shopName' && (sortConfig.direction === 'asc' ? <i className="fa-solid fa-arrow-up"></i> : <i className="fa-solid fa-arrow-down"></i>)}
                                        </TableCell>
                                        <TableCell className="th text-center" onClick={() => handleSort('orders')}>
                                            Orders
                                            {sortConfig.key === 'orders' && (
                                            sortConfig.direction === 'asc' ? (
                                                <i className="ms-1 fa-solid fa-arrow-up"></i>
                                            ) : (
                                                <i className="ms-1 fa-solid fa-arrow-down"></i>
                                            )
                                            )}
                                        </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredCustomers &&filteredCustomers.length>0? filteredCustomers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((customer, index) => (
                                            <React.Fragment>
                                                <TableRow key={index}>
                                                    <TableCell className='td text-center'>{index + 1}</TableCell>
                                                    <TableCell className='td text-center '>{customer.customer_Name}</TableCell>
                                        <TableCell className='td text-center '>{customer?.email||"Nil"}</TableCell>
                                        <TableCell className='td text-center '>{customer.phone}</TableCell>
                                        <TableCell className='td text-center '>{customer.gender}</TableCell>
                                        {/* <TableCell className='td text-center '>{customer.orders && customer.orders.length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'received').length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'processing').length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'completed').length}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders && customer.orders.filter(order => order.order_Status.trim().toLowerCase() === 'delivered').length}</TableCell> */}
                                        <TableCell className='td text-center '>{customer.address}</TableCell>
                                        <TableCell className='text-center td'>{customer.shopName}/{customer.shopPhone}</TableCell>
                                        <TableCell className='td text-center '>{customer.orders}</TableCell>
                                                    {/* <TableCell className='text-center td'>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => toggleOpen(customer._id)}
                                                        >
                                                            {openDetails === customer._id ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                        </IconButton>


                                                    </TableCell> */}
                                                </TableRow>
                                                {/* Orders Nested Details */}
                                                {/* <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={12}>
                                                        <Collapse in={openDetails === customer._id} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }} >
                                                                <div className="d-flex justify-content-between searchbar pb-2 pt-3 px-1 my-auto">
                                                                    <div className="d-flex left my-auto">
                                                                        <h5>Orders details</h5>
                                                                    </div>
                                                                </div>
                                                                <Table aria-label="orders">
                                                                    <TableHead className='order_header'>
                                                                        <TableRow>
                                                                            <TableCell className='th text-center '>#</TableCell>
                                                                            <TableCell className='th text-center '>Order Date</TableCell>
                                                                            <TableCell className='th text-center '>Suits</TableCell>
                                                                            <TableCell className='th text-center '>Amount</TableCell>
                                                                            <TableCell className='th text-center '>Total</TableCell>
                                                                            <TableCell className='th text-center '>Paid</TableCell>
                                                                            <TableCell className='th text-center '>Shop</TableCell>
                                                                            <TableCell className='th text-center '>Shop Address</TableCell>
                                                                            <TableCell className='th text-center '>Delivery Date</TableCell>
                                                                            <TableCell className='th text-center '>Updated on</TableCell>
                                                                            <TableCell className='th text-center '>Status</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {customer.orders.length > 0 ? customer.orders.map((order, index) => (
                                                                            <TableRow key={order._id}>
                                                                                <TableCell className='text-center td'>{index + 1}</TableCell>
                                                                                <TableCell className='text-center td'>{new Date(order.createdAt).toLocaleDateString('en-GB').split('-').join('')}</TableCell>
                        
                                                                                <TableCell className='text-center td'>{order.total_Suites}</TableCell>
                                                                                <TableCell className='text-center td'>{order.amount_Per_Suite.toLocaleString()}</TableCell>
                                                                                <TableCell className='text-center td'>{(order.amount_Per_Suite * order.total_Suites).toLocaleString()}</TableCell>
                                                                                <TableCell className='text-center td'>{order.paid_Amount.toLocaleString()}</TableCell>
                                                                                <TableCell className='text-center td'>{order.name}</TableCell>
                                                                                <TableCell className='text-center td'>{order.address}</TableCell>
                                                                                <TableCell className='text-center td'>{new Date(order.delivery_Date).toLocaleDateString()}</TableCell>
                                                                                <TableCell className='text-center td'>{new Date(order?.update_On_Date)?.toLocaleDateString()}</TableCell>
                                                                                <TableCell className='text-center td'>{order.order_Status.toLowerCase() === 'received' ? <span className="received_Status px-2 py-1">Received</span> : order.order_Status.toLowerCase() === 'processing' ? <span className="process_Status px-2 py-1">Processing</span> : order.order_Status.toLowerCase() === 'completed' ? <span className="completed_Status  px-2 py-1">Completed</span> : <span className="delivered_Status  px-2 py-1">Delivered</span>}</TableCell>
                                                                            </TableRow>
                                                                        )) :
                                                                            <TableRow>

                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell className='td text-center '>Orders not found!</TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                                <TableCell></TableCell>
                                                                            </TableRow>
                                                                        }


                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow> */}
                                            </React.Fragment>
                                        )):
                                        <TableRow className='border-0'>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        {/* <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell> */}
                                        <TableCell className='td text-center'>No result</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        {/* <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell> */}
                                    </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={filteredCustomers&&filteredCustomers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  style={{
                    color: '#5932EA',
                    fontSize: '12px',
                    fontWeight: '700',
                    textTransform: 'capitalize',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                        </div>
                    }
                </div>
            </div>
            {/* Custom Date Range Modal */}
            <Dialog className='custom_date_modal p-0'
                open={openModal}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-12 text-center pb-2 px-0 m-0">
                            <div className="content px-4 text-center">
                                <h6 className='my-3 '>Custom filter!</h6>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <div>
                                        <TextField
                                            id="date-from"
                                            label="Date From"
                                            type="date"
                                            value={dateFrom}
                                            onChange={(e) => setDateFrom(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,  // Ensures label doesn't overlap with date input
                                            }}
                                            sx={{
                                                '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                                                '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                                                '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                                            }}
                                        />
                                        <TextField
                                            id="date-to"
                                            label="Date To"
                                            type="date"
                                            value={dateTo}
                                            onChange={(e) => setDateTo(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,  // Ensures label doesn't overlap with date input
                                            }}
                                            sx={{
                                                '& .MuiInputBase-root': { height: '35px' },  // Minimize height
                                                '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
                                                '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
                                            }}
                                        />
                                    </div>
                                </Box>
                            </div>
                            <button className="cancel m-1 py-1 px-2" onClick={() => { handleCloseModal(); setDateFrom(''); setDateTo(''); setFilterValue('') }}>Cancel</button>
                            <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
                        </div>
                    </div>
                </div>

            </Dialog>
        </div>
    )
}
