import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    offers: []
};

export const offersSlice = createSlice({
    name: 'offersSlice',
    initialState,
    reducers: {
        // 1- offers Reducers
        // a- getting for Offers
        getAllOffers: (state, action) => {
            state.offers = action.payload;
        },
      
    },
});

// Action creators are generated for each case reducer function
export const { getAllOffers} = offersSlice.actions;

export default offersSlice.reducer;
