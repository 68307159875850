import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allAdmins: []
};

export const adminsRoleSlice = createSlice({
    name: 'adminsRoleSlice',
    initialState,
    reducers: {
        
        getAllAdmins: (state, action) => {
            state.allAdmins = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { getAllAdmins } = adminsRoleSlice.actions;

export default adminsRoleSlice.reducer;
