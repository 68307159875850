import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png';
import LoginHook from '../hooks/LoginHook';
import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isLoading, error, success, emptyFields, adminLogin } = LoginHook();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    document.title = 'Login';
    if (error || success) {
      setSnackbarMessage(success || error);
      setSnackbarOpen(true);
    }
  }, [error, success]);

  const handleLogin = async (e) => {
    e.preventDefault();
    await adminLogin(email, password);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleExitedSnackbar = () => {
    setSnackbarMessage('');
  };

  return (
    <>
      <div className="container-fluid admin_login">
        <div className="row justify-content-center align-items-center h-100 p-0">
          <div className="col-lg-3 col-md-4 col-sm-12 my-auto">
            <form onSubmit={handleLogin}>
              <div className="mb-1 text-center image">
                <img src={logo} alt="Logo" />
              </div>
              <div className="mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                  />
                </svg>
                <input
                  type="email"
                  required
                  className={
                    Array.isArray(emptyFields) && emptyFields.includes('email') ? 'error' : ''
                  }
                  placeholder="EMAIL ADDRESS"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
                <input
                  type="password"
                  required
                  placeholder="PASSWORD"
                  className={
                    Array.isArray(emptyFields) && emptyFields.includes('password') ? 'error' : ''
                  }
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button className="btn shadow">
                {isLoading ? 'Signing you in...' : 'Sign in'}
              </button>
              <div className="text-end mt-1">
                <Link to="#">Forgot password?</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        onExited={handleExitedSnackbar}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
