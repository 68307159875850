import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    adminProfile: {}
};

export const adminProfileSlice = createSlice({
    name: 'adminProfileSlice',
    initialState,
    reducers: {
        
        getAdminProfile: (state, action) => {
            state.adminProfile = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { getAdminProfile } = adminProfileSlice.actions;

export default adminProfileSlice.reducer;
