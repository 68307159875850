import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    packages: []
};

export const packagesSlice = createSlice({
    name: 'packagesSlice',
    initialState,
    reducers: {
        // 1- Packages Reducers
        // a- getting get Packages
        getAllPackages: (state, action) => {
            state.packages = action.payload;
        },
        // b- updating a Packages
        updatePackage: (state, action) => {
            const data = action.payload; // Assuming action.payload is the transaction object
            const index = state.packages.findIndex(packages => packages._id === data._id);
            if (index !== -1) {
                state.packages[index] = { ...state.packages[index], ...data };
            }
        }
    },
});

// Action creators are generated for each case reducer function
export const { getAllPackages, updatePackage } = packagesSlice.actions;

export default packagesSlice.reducer;
