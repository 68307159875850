import React, { useState, useEffect,useRef } from 'react'
import { useAuthContext } from '../hooks/adminAuthHook'
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer,TablePagination, TableHead, TableRow, Dialog, Box, Skeleton,TextField } from '@mui/material';
import ReportsHook from '../hooks/ReportsHook'

export default function Reports() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { admin } = useAuthContext()
  const { loading, getReports } = ReportsHook()
  useEffect(() => {
    document.title = 'Reports & feedback';
    getReports()
  }, [admin])
  const reports = useSelector((state) => state.reports.reports);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filtering Reports
  const [search, setSearch] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const filteredReports = reports && reports
  .filter(report => {
    const name = report.name?.trim().toLowerCase() || '';
    const email = report.email?.trim().toLowerCase() || '';
    const phone = report.phone?.trim().toLowerCase() || '';
    const status = report.status?.trim().toLowerCase() || '';
  
    const matchesSearch = (
      search.trim() === '' ||  // If search is empty, skip this condition
      name.includes(search.trim().toLowerCase()) ||
      email.includes(search.trim().toLowerCase()) ||
      phone.includes(search.trim().toLowerCase())
    );

    const matchesStatus = (
      searchStatus.trim() === '' ||  // If searchStatus is empty, skip this condition
      status.includes(searchStatus.trim().toLowerCase())
    );

    return matchesSearch && matchesStatus;
  })
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


    const [audio, setAudio] = useState(null);
    const [audioId, setAudioId] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null); // Add an audio reference
    
    const handleAudioPlayPause = (report) => {
      if (audioRef.current && isPlaying && audioId === report._id) {
        // Pause if the same audio is playing
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        // Pause any existing audio first
        if (audioRef.current) {
          audioRef.current.pause();
          setIsPlaying(false);
        }
        try {
          // Set the new audio and play it
          setIsPlaying(true);
          setAudioId(report._id);
          setAudio(report.audio_Note); // Store the audio note
        } catch (error) {
          console.error("Error playing audio:", error);
        }
      }
    };
    
    const handleAudioStop = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset to the beginning
        setIsPlaying(false);
        setAudioId('');
      }
    };
    

  // Handle Details view
  const [details,setDetails]=useState('')
  const [openDetail,setOpenDetails]=useState(false)
  const handleOpenDetailsView=(report)=>{
    setDetails(report)
    setOpenDetails(true)
  }
  const handleCloseDetailsView=()=>{
    setDetails('')
    setOpenDetails(false)
  }

   // Handle Update Status
   const [status,setStatus]=useState('')

   const [openUpdate,setOpenUpdate]=useState(false)
   const handleOpenUpdateView=(report)=>{
     setDetails(report)
     setOpenUpdate(true)
   }
   const handleCloseUpdateView=(text_Note)=>{
     setDetails('')
     setStatus('')
     setOpenUpdate(false)
   }

       // Updating Report Status
  const [updateLoading, setUpdateLoading] = useState(false)

  const updateReportStatus = async (e) => {
    e.preventDefault()
    setUpdateLoading(true)
    try {
      const response = await fetch(`${apiUrl}/auth/reports/update/status`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({reportId: details && details._id,status })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getReports()
        setUpdateLoading(null)
        setDetails('')
        setOpenUpdate(false)
      }
      if (!response.ok) {
        alert(json.message)
        setUpdateLoading(null)
      }
    } catch (error) {
      alert(error.message)
        setUpdateLoading(null)
    }
  }

  useEffect(() => {
    setPage(0)
  }, [search])

  const collapsed = useSelector((state) => state.collapsed.collapsed);

  return (
    <div className={`${collapsed ? "collapsed packages mt-5" : "main packages mt-5"}`}>
        <div className="container-fluid bg-none my-0 mt-4">
            <div className="row px-3 mt-md-5">
            {loading && (reports && reports.length < 1) ?
            <>
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            </>
            :
            <div className="col-md-12 data_table p-2 pb-5">
              <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                <h6>All Reports </h6>
                </div>
                <div className="d-flex right my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                  <input type="text"  value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                  <select name="" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)} placeholder='Seacrh..' className='mx-1' id="">
                    <option value="">Choose status</option>
                    <option value="New">New</option>
                    <option value="In Progress">In Progress</option>
                    <option value="To be Addressed">To be Addressed</option>
                    <option value="Duplicate">Duplicate</option>
                    <option value="Critical">Critical</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Resolved">Resolved</option>
                   
                  </select>
                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='th text-center'>SN</TableCell>
                      <TableCell className='th text-center'>Date</TableCell>
                      <TableCell className='th text-center'>Reported By</TableCell>
                      <TableCell className='th text-center'>Phone</TableCell>
                      <TableCell className='th text-center'>Address</TableCell>
                      <TableCell className='th text-center'>Report Status</TableCell>
                      <TableCell className='th text-center'>Audio Message</TableCell>
                      <TableCell className='th text-center'>Details</TableCell>
                   
                      <TableCell className='th text-center'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredReports && filteredReports.length>0? filteredReports
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((report, index) => (
                      <React.Fragment>
                        <TableRow key={index}>
                          <TableCell className='td text-center'>{index + 1}</TableCell>
                          <TableCell className='text-center td'>
                          {new Date(report.createdAt).toLocaleDateString('en-GB').split('-').join('')}
                        </TableCell>
                          <TableCell className='td text-center'>{report.name}</TableCell>
                          <TableCell className='td text-center'>{report.phone}</TableCell>
                          <TableCell className='td text-center'>{report.address}</TableCell>
                          <TableCell className="td text-center"><span className={`${report?.status?.trim().toLowerCase()==='new' ? "badge bg-danger p-1 rounded text-sm fa-fade":" p-1 rounded"}`}>{report?.status}</span></TableCell>
                          <TableCell className="td text-center">
                            {isPlaying && audioId === report._id ? (
                              <div className="audio-container">
                                <audio
                                  ref={audioRef}
                                  src={audio}
                                  controls
                                  autoPlay
                                  onEnded={() => {
                                    setIsPlaying(false);
                                    setAudioId('');
                                  }}
                                  className="custom-audio"
                                />
                                <button
                                  className="stop_btn px-2 mx-1 py-1"
                                  onClick={handleAudioStop}
                                >
                                  <i className="fa-solid fa-stop"></i>
                                </button>
                              </div>
                            ) : (
                              <button
                                disabled={!report.audio_Note}
                                className="play_btn px-2 py-1 mx-1"
                                onClick={() => handleAudioPlayPause(report)}
                              >
                                <i className="fa-solid fa-play"></i>
                              </button>
                            )}
                          </TableCell>
                          <TableCell className='td text-center'>{report.text_Note?<button className='border-0 view_btn' onClick={()=>handleOpenDetailsView(report)}>View</button>:"No details"}</TableCell>
                            <TableCell className='td text-center'>
                             <button className="edit_btn py-1" onClick={()=>handleOpenUpdateView(report)}>Mark as</button>
                            </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )):
                    <TableRow className='border-0'>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className='td text-center'>No result</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={filteredReports&&filteredReports.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  style={{
                    color: '#5932EA',
                    fontSize: '12px',
                    fontWeight: '700',
                    textTransform: 'capitalize',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
          }
            </div>
        </div>

        {/* Vieewing Record details modal */}

 <Dialog className='p-0'
        open={openDetail}
        onClose={handleCloseDetailsView}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 pb-2 px-0 m-0">
             <div className="details_text p-4">
              <h4>Reported by: <strong>{details.name} ({details.phone})</strong></h4>
              <p><strong>Message:</strong> {details.text_Note}</p>
             </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Updating Status */}
      <Dialog className='p-0'
        open={openUpdate}
        onClose={handleCloseUpdateView}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 pb-2 px-0 m-0">
            <form onSubmit={updateReportStatus}>
                <div className="card add_new_package_card">
                  <div className="card-header">
                    Update Report Status
                    </div>
                  <div className="card-body">
                    <div className="data my-1">
                      <label htmlFor="">Status:</label>
                    <select name="" id="" value={status} onChange={(e)=>setStatus(e.target.value)}>
                    <option value="">Choose status</option>
                      <option value="In Progress">In Progress</option>
                      <option value="To be Addressed">To be Addressed</option>
                      <option value="Duplicate">Duplicate</option>
                      <option value="Critical">Critical </option>
                      <option value="Rejected">Rejected </option>
                      <option value="Resolved">Resolved </option>

                    </select>
                    </div>
                
                    <div className="text-end mt-3">
                      <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCloseUpdateView()} disabled={updateLoading}>Cancel</button>
                      <button className="btn save_btn mx-1" type='submit' disabled={updateLoading||status===''||status?.trim().toLowerCase()===details?.status?.trim().toLowerCase()}>{updateLoading ? "Saving" : "Save"}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
