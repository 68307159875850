import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer,TablePagination, TableHead, TableRow, Dialog, Box, Skeleton,TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import TransactionsHook from '../hooks/TransactionsHook'
import { useAuthContext } from '../hooks/adminAuthHook'
import { updateTransaction } from '../redux/reducers/transactionsSlice'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PackagesHook from '../hooks/PackagesHook';

export default function Transactions() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch()
  const { admin } = useAuthContext()
  const { loading, getTransactions } = TransactionsHook()
  const { getPackages } = PackagesHook();
  useEffect(() => {
    document.title = 'Transactions';
    getTransactions()
    getPackages()
  }, [admin])

  const packages = useSelector((state) => state.packages.packages);
  const transactions = useSelector((state) => state.transactions.transactions)
  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState('')
  const [statusValue, setStatusValue] = useState('')

  const[dateFrom,setDateFrom]=useState('')
  const[dateTo,setDateTo]=useState('')
  const today = new Date();
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

const isWithinFilter = (transaction) => {
  const transactionCreatedAt = new Date(transaction.createdAt);

  // Ensure date strings are converted to Date objects and are valid
  const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

  if (filterValue === 'today') {
    return transactionCreatedAt.toDateString() === today.toDateString();
  } else if (filterValue === 'thisWeek') {
    return transactionCreatedAt >= sevenDaysAgo && transactionCreatedAt <= today;
  } else if (filterValue === 'custom') {
    // Handle empty strings gracefully
    const fromDate = dateFrom ? new Date(dateFrom) : null;
    const toDate = dateTo ? new Date(dateTo) : null;

    // Ensure both dates are valid
    if ((!fromDate || !isValidDate(fromDate)) || (!toDate || !isValidDate(toDate))) {
      return false; // Invalid or missing date
    }

    // Check if transactionCreatedAt is within the custom date range
    return transactionCreatedAt >= fromDate && transactionCreatedAt <= toDate;
  } else {
    return true; // 'all' case or any other default case
  }
};

const filteredTransactions = transactions && transactions
  .filter(transaction => {
    const matchesSearch = (
      transaction.name?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      transaction.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
      transaction.phone?.trim().includes(search.trim()) ||
      transaction.createdAt.includes(search.trim())
    );

    // Check if statusValue matches the transaction's isActivated status
    const matchesStatus = statusValue === '' || 
    (statusValue === 'Approved' && transaction.status==='Approved') ||
    (statusValue === 'Rejected' && transaction.status==='Rejected') ||
    (statusValue === 'Pending' && transaction.status==='Pending');

    // Only include transactions that match the search criteria, status filter, and are within the filter range
    return matchesSearch && matchesStatus && isWithinFilter(transaction);
  })
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  // Activate/Inactivate state
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [package_Name, setPackage_Name] = useState('')
  const [days, setDays] = useState()
  const [price, setPrice] = useState()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (transactions) => {
    setOpen(true);
    setSelectedTransaction(transactions)

  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTransaction('')
  }

  
  const [openReject, setOpenReject] = React.useState(false);
  const handleClickOpenReject = (transactions) => {
    setOpenReject(true);
    setSelectedTransaction(transactions)

  };

  const handleCloseReject = () => {
    setOpenReject(false);
    setSelectedTransaction('')
  }

  const [updateLoading, setUpdateLoading] = useState(null)
  const updateTransactionStatus = async (status) => {
    setUpdateLoading(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/transactions/approve`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ shopId: selectedTransaction && selectedTransaction.shopId,transactionId:selectedTransaction && selectedTransaction._id,package_Name,days,status:status,price})
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        setOpen(false);
        setOpenReject(false);
        setUpdateLoading(null)
        dispatch(updateTransaction(json.data))
      }
      if (!response.ok) {
        alert(json.message)
        setUpdateLoading(null)
      }
    } catch (error) {
      setUpdateLoading()
    }
  }


  // filter menu

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuStyles = {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
  };

  const menuItemStyles = {
    padding: '4px 12px',
    fontSize: '12px',
    color: '#000',
    cursor: 'pointer'
  };

  const menuItemHoverStyles = {
    backgroundColor: '#f5f5f5'
  };

  // custom date range filter
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpenModal = (transactions) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  }

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    setPage(0)
  }, [search,dateFrom,dateTo,filterValue,statusValue])

  const collapsed = useSelector((state) => state.collapsed.collapsed);

  const [viewSlip,setViewSlip]=useState('')
  const [viewModal,setViewModal]=useState(false)
  const handleViewSlip=(slip)=>{
    setViewSlip(slip)
    setViewModal(true)
  }
  const handleCloseViewSlip=(slip)=>{
    setViewSlip('')
    setViewModal(false)
  }
  return (
    <div className={`${collapsed ?"collapsed dashboard mt-5":"main dashboard mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-3 mt-md-5">
          {loading && (transactions && transactions.length < 1) ?
            <>
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
              <Skeleton className='my-1' animation="wave" variant="rounded" height={60} />
            </>
            :
            <div className="col-md-12 data_table p-2 pb-5">
              <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                <h6>{filterValue === 'thisWeek' ? "This week" : filterValue === 'today' ? "Today" :filterValue === 'custom' ? "Custom date" : "All"} Transactions <span>{dateFrom && dateTo &&`( ${dateFrom} to ${dateTo} )`}</span></h6>
                </div>
                <div className="d-flex right my-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                  </svg>
                  <input type="text"  value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                  <select name="" value={statusValue} onChange={(e) => setStatusValue(e.target.value)} placeholder='Seacrh..' className='mx-1' id="">
                    <option value="">Status</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Pending">Pending</option>
                  </select>
                  <button
                    className='filter_btn'
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>

                  </button>
                  <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    style: menuStyles,
                  }}
                >
                  <MenuItem
                    onClick={() => { handleMenuClose(); setFilterValue(''); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => { handleMenuClose(); setFilterValue('today'); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Today
                  </MenuItem>
                  <MenuItem
                    onClick={() => { handleMenuClose(); setFilterValue('thisWeek'); setDateFrom(''); setDateTo('') }}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    This Week
                  </MenuItem>
                  <MenuItem
                    onClick={()=>{handleClickOpenModal();setFilterValue('custom')}}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Custom Date
                  </MenuItem>
                  <MenuItem
                    onClick={()=>{setFilterValue('');setDateFrom('');setDateTo('')}}
                    style={menuItemStyles}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = menuItemHoverStyles.backgroundColor}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Default
                  </MenuItem>
                </Menu>
                </div>
              </div>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className='th text-center'>SN</TableCell>
                      <TableCell className='th text-center'>Date</TableCell>
                      <TableCell className='th text-center'>Shop Name</TableCell>
                      <TableCell className='th text-center'>Phone</TableCell>
                      <TableCell className='th text-center'>Address</TableCell>
                      <TableCell className='th text-center'>Package Name</TableCell>
                      <TableCell className='th text-center'>Days</TableCell>
                      <TableCell className='th text-center'>Transaction Status</TableCell>
                      <TableCell className='th text-center'>Receipt</TableCell>
                      <TableCell className='th text-center'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTransactions && filteredTransactions.length>0? filteredTransactions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((transactions, index) => (
                      <React.Fragment>
                        <TableRow key={index}>
                          <TableCell className='td text-center'>{index + 1}</TableCell>
                          <TableCell className='text-center td'>
                          {new Date(transactions.createdAt).toLocaleDateString('en-GB').split('-').join('')}
                        </TableCell>
                          <TableCell className='td text-center'>{transactions.name}</TableCell>
                          <TableCell className='td text-center'>{transactions.phone}</TableCell>
                          <TableCell className='td text-center'>{transactions.address}</TableCell>
                          <TableCell className='td text-center'>{transactions?.package_Name || "Nil"}</TableCell>
                          <TableCell className='td text-center'>{transactions?.days || "0"}</TableCell>
                          <TableCell className='td text-center'>{transactions.status?.toLowerCase()==='approved' ? <span className="active_span px-2 py-1">Approved</span>:transactions.status?.toLowerCase()==='pending' ?<span className="pending_span px-2 py-1">Pending</span>:transactions.status?.toLowerCase()==='rejected' &&<span className="rejected_span px-2 py-1">Rejected</span>}</TableCell>
                          <TableCell className='td text-center'>{transactions.receipt_pic ?<img onClick={()=>handleViewSlip(transactions.receipt_pic)} src={transactions.receipt_pic} alt='Receipt' className='rounded' />: "No Receipt"}</TableCell>
                          <TableCell className='td text-center'>
                          <div class="btn-group mr-2" role="group" aria-label="First group">
                            {transactions.status?.toLowerCase()==='approved'&& <button onClick={() => handleClickOpen(transactions)} className="activate_shops py-1 px-2 mx-1"disabled >Approved</button>}
                            {transactions.status?.toLowerCase()==='rejected'&& <button onClick={() => handleClickOpen(transactions)} className="inactivate_shops py-1 px-2 mx-1"disabled >Rejected</button>}
                            {transactions.status?.toLowerCase()==='pending' &&
                            <>
                             <button onClick={() => handleClickOpen(transactions)} className="activate_shops py-1 px-2 mx-1">Approve</button>
                             <button onClick={() => handleClickOpenReject(transactions)} className="inactivate_shops py-1 px-2 ">Reject</button>
                            </>
                            }
                         
                          </div>
                           
                          </TableCell>
                        </TableRow>
                     

                      </React.Fragment>
                    )):
                    <TableRow className='border-0'>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className='td text-center'>No result</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component='div'
                  count={filteredTransactions&&filteredTransactions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  style={{
                    color: '#5932EA',
                    fontSize: '12px',
                    fontWeight: '700',
                    textTransform: 'capitalize',
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
          }
        </div>
      </div>


      {/* Approving Modal */}
      <Dialog className='shops_modal p-0'
        open={open}
        onClose={handleClose}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
           
             <div className="alert_icon text-center">
                <div className="activate_icon  py-2">
                  
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to Approve {selectedTransaction && selectedTransaction.name}'s' Transaction?</p>
                  <select className='mb-3' name="" id="" value={package_Name} onChange={(e)=>setPackage_Name(e.target.value)} required> 
                    
                    <option value="">Select</option>
                    {packages &&packages.filter(data=>data.isActive).map((data,index)=>(
                      <option value={data.name} key={index}>{data.name} {data.price.toLocaleString()} PKR</option>
                    ))}
                    <option value="Custom">Custom</option>
                  </select>
                  {package_Name.toLowerCase()==='custom' &&
                  <>
                  <input type="number" className='mb-3'placeholder='Package days' value={days}  onChange={(e) => setDays(parseInt(e.target.value, 10))} required/>
                  <input type="number" className='mb-3'placeholder='Custom Package Price (PKR)' value={price}  onChange={(e) => setPrice(parseInt(e.target.value, 10))} required/>
                  </>
                  
                  }
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2" type='button' disabled={updateLoading} onClick={handleClose}>Cancel</button>
              <button className="activate m-1 py-1 px-2" type='submit' disabled={updateLoading ||(package_Name==='' ||package_Name===undefined) ||(package_Name==='Custom'&&days===0)} onClick={()=>updateTransactionStatus('Approved')} >{updateLoading ? "Approving" : "Approve"}</button>
            </div>
          </div>
        </div>

      </Dialog>


{/* Rejection Modal */}
<Dialog className='shops_modal p-0'
        open={openReject}
        onClose={handleCloseReject}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
          
             <div className="alert_icon text-center">
                <div className="inactivate_icon  py-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to Reject {selectedTransaction && selectedTransaction.name}'s' Transaction?</p>
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2" type='button' disabled={updateLoading} onClick={handleCloseReject}>Cancel</button>
              <button className="inactivate m-1 py-1 px-2" type='submit' disabled={updateLoading} onClick={()=>updateTransactionStatus('Rejected')}>{updateLoading ? "Rejecting" : "Reject"}</button>
          
            </div>
          </div>
        </div>

      </Dialog>

       {/* Custom Date Range Modal */}
       <Dialog className='custom_date_modal p-0'
        open={openModal}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
                <div className="content px-4 text-center">
                  <h6 className='my-3 '>Custom filter!</h6>
                  <Box
  component="form"
  sx={{
    '& .MuiTextField-root': { m: 0.5, width: '15ch' },  // Reduced margin
  }}
  noValidate
  autoComplete="off"
>
  <div>
    <TextField
      id="date-from"
      label="Date From"
      type="date"
      value={dateFrom}
      onChange={(e)=>setDateFrom(e.target.value)}
      InputLabelProps={{
        shrink: true,  // Ensures label doesn't overlap with date input
      }}
      sx={{
        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
      }}
    />
    <TextField
      id="date-to"
      label="Date To"
      type="date"
      value={dateTo}
      onChange={(e)=>setDateTo(e.target.value)}
      InputLabelProps={{
        shrink: true,  // Ensures label doesn't overlap with date input
      }}
      sx={{
        '& .MuiInputBase-root': { height: '35px' },  // Minimize height
        '& .MuiInputLabel-root': { fontSize: '12px' }, // Minimize label font size
        '& .MuiInputBase-input': { fontSize: '12px' }  // Minimize input text font size
      }}
    />
  </div>
</Box>
                </div>
              <button className="cancel m-1 py-1 px-2" onClick={()=>{handleCloseModal();setDateFrom(''); setDateTo('');setFilterValue('')}}>Cancel</button>
              <button className="cancel m-1 py-1 px-2 active" onClick={handleCloseModal}>Filter</button>
            </div>
          </div>
        </div>

      </Dialog>


      {/* View Payment Slip Modal */}
      <Dialog
  className="shops_modal p-0"
  open={viewModal}
  onClose={handleCloseViewSlip}
  keepMounted
  aria-describedby="alert-dialog-slide-description"
  
>
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 text-center pb-2 px-0 m-0">
        <div className="image-container">
          {viewSlip && <img src={viewSlip} alt="View Slip" className="slip-image" />}
        </div>
      </div>
    </div>
  </div>
</Dialog>

    </div>
  )
}
