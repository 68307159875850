import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shopsReferrals: []
};

export const shopsReferralsSlice = createSlice({
    name: 'shopsReferralsSlice',
    initialState,
    reducers: {
        
        getAllShopsReferrals: (state, action) => {
            state.shopsReferrals = action.payload;
        },
      
    },
});

// Action creators are generated for each case reducer function
export const { getAllShopsReferrals} = shopsReferralsSlice.actions;

export default shopsReferralsSlice.reducer;
