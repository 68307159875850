import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rewards: []
};

export const rewardsSlice = createSlice({
    name: 'rewardsSlice',
    initialState,
    reducers: {
        // 1- offers Reducers
        // a- getting for Offers
        getAllRewards: (state, action) => {
            state.rewards = action.payload;
        },
      
    },
});

// Action creators are generated for each case reducer function
export const { getAllRewards} = rewardsSlice.actions;

export default rewardsSlice.reducer;
