import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    payoutPaymentMethods: []
};

export const payoutPaymentMethodsSlice = createSlice({
    name: 'payoutPaymentMethodsSlice',
    initialState,
    reducers: {
      
        getAllPayoutPaymentMethods: (state, action) => {
            state.payoutPaymentMethods = action.payload;
        },
      
    },
});

// Action creators are generated for each case reducer function
export const { getAllPayoutPaymentMethods} = payoutPaymentMethodsSlice.actions;

export default payoutPaymentMethodsSlice.reducer;
