import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ManageAdmins from './ManageAdmins';

export default function Setting() {
  const collapsed = useSelector((state) => state.collapsed.collapsed);
  useEffect(() => {
    document.title = 'Setting';

  }, []);
  return (
    <div className={`${collapsed ? "collapsed packages mt-5" : "main packages mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-2">
            <div className="col-md-12 mt-4">
                {/* Nav Pills */}
            <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
              <li className="nav-item m-1" role="presentation">
                <button className="active" id="pills-admins-tab" data-bs-toggle="pill" data-bs-target="#pills-admins" type="button" role="tab" aria-controls="pills-admins" aria-selected="true">
                  Manage Admins
                </button>
              </li>
            </ul>

            {/* Tab Content */}
            <div className="tab-content" id="pills-tabContent">
              {/*Shops Referrals Tab Pane */}
              <div className="tab-pane fade show active" id="pills-shop_referrals" role="tabpanel" aria-labelledby="pills-shop_referrals-tab">
                <ManageAdmins />
              </div>
             
            </div>
            </div>
        </div>
      </div>
    </div>
  )
}
