import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    reports: []
};

export const reportsSlice = createSlice({
    name: 'reportsSlice',
    initialState,
    reducers: {
        // 1- Packages Reducers
        // a- getting get reports
        getAllReports: (state, action) => {
            state.reports = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getAllReports } = reportsSlice.actions;

export default reportsSlice.reducer;
