import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    trials: []
};

export const trialsSlice = createSlice({
    name: 'trialsSlice',
    initialState,
    reducers: {
        // 1- Trials Reducers
        // a- getting get trials
        getAllTrials: (state, action) => {
            state.trials = action.payload;
        },
        // b- updating a trials
        updateTrial: (state, action) => {
            const data = action.payload; // Assuming action.payload is the transaction object
            const index = state.trials.findIndex(trial => trial._id === data._id);
            if (index !== -1) {
                state.trials[index] = { ...state.trials[index], ...data };
            }
        }
    },
});

// Action creators are generated for each case reducer function
export const { getAllTrials, updateTrial } = trialsSlice.actions;

export default trialsSlice.reducer;
