import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: []
};

export const alertnotificationsSlice = createSlice({
    name: 'alertnotificationsSlice',
    initialState,
    reducers: {
        // 1- Customers Reducers
        // a- getting get customers
        getAllAlertNotifications: (state, action) => {
            state.notifications = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getAllAlertNotifications } = alertnotificationsSlice.actions;

export default alertnotificationsSlice.reducer;
