import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReferralRewardSetting from './ReferralRewardSetting';
import AllReferrals from './AllReferrals';
import WithdrawRequests from './WithdrawRequests';
import ReferralsPayouts from './ReferralsPayouts';
import ShopsReferrals from './ShopsReferrals';
import PayoutPaymentMethods from './PayoutPaymentMethods';
export default function Referrals() {
  const collapsed = useSelector((state) => state.collapsed.collapsed);
  const adminProfile = useSelector((state) => state.adminProfile.adminProfile);

  useEffect(() => {
    document.title = 'Referrals';

  }, []);

  return (
    <div className={`${collapsed ? "collapsed packages mt-5" : "main packages mt-5"}`}>
      <div className="container-fluid bg-none my-0 mt-4">
        <div className="row px-2">
          <div className="col-md-12 mt-4">
            {/* Nav Pills */}
            <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
              <li className="nav-item m-1" role="presentation">
                <button className="active" id="pills-shop_referrals-tab" data-bs-toggle="pill" data-bs-target="#pills-shop_referrals" type="button" role="tab" aria-controls="pills-shop_referrals" aria-selected="true">
                  Shop Referrals
                </button>
              </li>
              <li className="nav-item m-1" role="presentation">
                <button className="" id="pills-referrals-tab" data-bs-toggle="pill" data-bs-target="#pills-referrals" type="button" role="tab" aria-controls="pills-referrals" aria-selected="true">
                  Referrals
                </button>
              </li>
              {adminProfile && adminProfile?.role?.trim().toLowerCase() === 'super admin' &&
                <>
                  <li className="nav-item m-1" role="presentation">
                    <button className="" id="pills-payouts-tab" data-bs-toggle="pill" data-bs-target="#pills-payouts" type="button" role="tab" aria-controls="pills-payouts" aria-selected="false">
                      Payouts
                    </button>
                  </li>
                  <li className="nav-item m-1" role="presentation">
                    <button className="" id="pills-payout_requests-tab" data-bs-toggle="pill" data-bs-target="#pills-payout_requests" type="button" role="tab" aria-controls="pills-payout_requests" aria-selected="false">
                      Payout Requests
                    </button>
                  </li>
                  <li className="nav-item m-1" role="presentation">
                    <button className="" id="pills-rewards-tab" data-bs-toggle="pill" data-bs-target="#pills-rewards" type="button" role="tab" aria-controls="pills-rewards" aria-selected="false">
                      Reward
                    </button>
                  </li>
                  <li className="nav-item m-1" role="presentation">
                    <button className="" id="pills-payout_methods-tab" data-bs-toggle="pill" data-bs-target="#pills-payout_methods" type="button" role="tab" aria-controls="pills-payout_methods" aria-selected="false">
                      Payout Methods
                    </button>
                  </li>
                </>
              }

            </ul>
            {/* Tab Content */}
            <div className="tab-content" id="pills-tabContent">
              {/*Shops Referrals Tab Pane */}
              <div className="tab-pane fade show active" id="pills-shop_referrals" role="tabpanel" aria-labelledby="pills-shop_referrals-tab">
                <ShopsReferrals />
              </div>
              {/* Referrals Tab Pane */}
              <div className="tab-pane fade" id="pills-referrals" role="tabpanel" aria-labelledby="pills-referrals-tab">
                <AllReferrals />
              </div>

              {/* Payouts Tab Pane */}
              {adminProfile && adminProfile?.role?.trim().toLowerCase() === 'super admin' &&
                <div className="tab-pane fade" id="pills-payouts" role="tabpanel" aria-labelledby="pills-payouts-tab">
                  <ReferralsPayouts />
                </div>
              }

              {/* Payout Requests Tab Pane */}
              {adminProfile && adminProfile?.role?.trim().toLowerCase() === 'super admin' &&
                <div className="tab-pane fade" id="pills-payout_requests" role="tabpanel" aria-labelledby="pills-payout_requests-tab">
                  <WithdrawRequests />
                </div>
              }

              {/* Rewards Setting Tab Pane */}
              {/* Payout Requests Tab Pane */}
              {adminProfile && adminProfile?.role?.trim().toLowerCase() === 'super admin' &&
                <div className="tab-pane fade" id="pills-rewards" role="tabpanel" aria-labelledby="pills-rewards-tab">
                  <ReferralRewardSetting />
                </div>
              }

              {/* Payout Payment Mehods Tab Pane */}
              {adminProfile && adminProfile?.role?.trim().toLowerCase() === 'super admin' &&
                <div className="tab-pane fade" id="pills-payout_methods" role="tabpanel" aria-labelledby="pills-payout_methods-tab">
                  <PayoutPaymentMethods />
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
