import {useState,useRef} from 'react'

import { useAuthContext } from './adminAuthHook'
import { getAllShops,getTotalShopsLength } from '../redux/reducers/shopsSlice'
import { useDispatch } from 'react-redux';

export default function HanldeShopsHook() {
  const abortCont = useRef(new AbortController());

    const dispatch = useDispatch()
    const apiUrl = process.env.REACT_APP_API_URL;
    const { admin } = useAuthContext()
    const [loading, setLoading] = useState(null)
    // Fetching Shops
    const getShops=async()=>{
      setLoading(true)
       try {
        const response=await fetch(`${apiUrl}/auth/admins/shops/super/admin/get/shops`,{
          headers:{
            'Authorization': `Bearer ${admin.token}`
            
          },
          signal: abortCont.current.signal

        })
        
        const json=await response.json()
        if(response.ok){
          setLoading(null)
          dispatch(getAllShops(json.data))
        }
        if(!response.ok){
            setLoading(null)
        }
       } catch (error) {
       console.log(error)
       setLoading(null)
       }
    }


    const getTotalShops=async()=>{
      setLoading(true)
       try {
        const response=await fetch(`${apiUrl}/auth/admins/shops/super/admin/get/totalshops`,{
          headers:{
            'Authorization': `Bearer ${admin.token}`
            
          },
          signal: abortCont.current.signal

        })
        
        const json=await response.json()
        if(response.ok){
          setLoading(null)
          dispatch(getTotalShopsLength(json.data))
        }
        if(!response.ok){
            setLoading(null)
        }
       } catch (error) {
       console.log(error)
       setLoading(null)
       }
    }
  return {getShops,getTotalShops,loading}
}
