import {useState,useRef} from 'react'
import { useAuthContext } from './adminAuthHook'
import { getAllPackages } from '../redux/reducers/packagesSlice'
import { useDispatch } from 'react-redux';
export default function PackagesHook() {
    const dispatch = useDispatch()
    const apiUrl = process.env.REACT_APP_API_URL;
    const { admin } = useAuthContext()
    const [loading, setLoading] = useState(null)
    // Fetching Packages
    const abortCont = useRef(new AbortController());

    const getPackages=async()=>{
      setLoading(true)
       try {
        const response=await fetch(`${apiUrl}/auth/admins/packages/get`,{
          headers:{
            'Authorization': `Bearer ${admin.token}`
            
          },
          signal: abortCont.current.signal

        })
        
        const json=await response.json()
        if(response.ok){
          setLoading(null)
          dispatch(getAllPackages(json.data))
        }
        if(!response.ok){
            setLoading(null)
        }
       } catch (error) {
       console.log(error)
       setLoading(null)
       }
    }

  return {getPackages,loading}
}
