import React,{useState,useEffect} from 'react'
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableHead, TableRow,Dialog } from '@mui/material'
import { useSelector } from 'react-redux';
import ManageAdminsHook from '../hooks/ManageAdminsHook';
import { useAuthContext } from '../hooks/adminAuthHook';

export default function PaymentMethods() {
  const { admin } = useAuthContext();
  const apiUrl = process.env.REACT_APP_API_URL;
  const allAdmins = useSelector((state) => state.allAdmins.allAdmins);
  const { getAdmins } = ManageAdminsHook(); // Assuming updateTrial is a function in TrialsHook
  useEffect(() => {
    getAdmins()
  }, [admin]);

  const rowsPerPageOptions = [10, 30, 60];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Activate/Inactivate state
  const [selectedAdmin, setSelectedPaymentMethod] = useState()
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (admin) => {
    setOpen(true);
    setSelectedPaymentMethod(admin)

  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSelectedPaymentMethod('')
    }, 1000);
  }
   // PaymentMethod Edition
   const [editedAdmin,setEditedAdmin]=useState(null)
   // Adding a new PaymentMethod
 
   const [email, setEmail] = useState("");
   const [role, setRole] = useState("");
   const [password, setPassword] = useState("");

   const [isActive, setIsActive] = useState();
   
   const [openNew, setOpenNew] = React.useState(false);
   const handleClickOpenNew = () => {
     setOpenNew(true);
   };
 
   const handleCloseNew = () => {
     setOpenNew(false);
     setEmail('')
     setRole('')
     setPassword('')
     setIsActive(true)
     setEditedAdmin('')
   }
 
 const handleEditView=(data)=>{
   setEditedAdmin(data);
   setOpenNew(true);
   setEmail(data.email);
   setRole(data.role);
   setIsActive(data.isActive);
  }
    // Handle Select Input for Boolean Values
    const handleSelectChange = (setter) => (e) => {
      const value = e.target.value === "true" ? true : false;
      setter(value);
    };

   // API Call to add a new paymentMethod
   const [loading1, setLoading1] = useState(false)
   const addNewAdmin = async (e) => {
     e.preventDefault()
     setLoading1(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/roles/add`, {
         method: "POST",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({email,role,password,isActive})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getAdmins()
         setOpen(false);
         setLoading1(null)
         handleCloseNew()
       }
       if (!response.ok) {
         alert(json.message)
         setLoading1(null)
       }
     } catch (error) {
       setLoading1(null)
     }
   }

   const updateAdmin = async (e) => {
     e.preventDefault()
     setLoading1(true)
     try {
       const response = await fetch(`${apiUrl}/auth/admins/roles/update`, {
         method: "PUT",
         headers: {
           'Authorization': `Bearer ${admin.token}`,
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({_id:editedAdmin._id,email,role,password,isActive})
       })
 
       const json = await response.json()
       if (response.ok) {
         alert(json.message)
         getAdmins()
         setOpen(false);
         setLoading1(null)
         handleCloseNew()
 
       }
       if (!response.ok) {
         alert(json.message)
         setLoading1(null)
       }
     } catch (error) {
       setLoading1(null)
     }
   }
   const [search, setSearch] = useState('');
   const filteredAdmins = allAdmins && allAdmins
     .filter(admin => {
       const matchesSearch = (
        admin.email?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        admin.role?.trim().toLowerCase().includes(search.trim().toLowerCase())
       )
 
       return matchesSearch
     })
     .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
     
     
   // Activating/Inactivating shops
  const [updateLoading, setUpdateLoading] = useState(false)
  const updateAdminStatus = async () => {
    setUpdateLoading(true)
    try {
      const response = await fetch(`${apiUrl}/auth/admins/roles/update/status`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${admin.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Id: selectedAdmin && selectedAdmin._id })
      })

      const json = await response.json()
      if (response.ok) {
        alert(json.message)
        getAdmins()
        setOpen(false);
        setUpdateLoading(null)

      }
      if (!response.ok) {
        alert(json.message)
        setUpdateLoading(null)
      }
    } catch (error) {
      setUpdateLoading(null)
    }
  }

  useEffect(() => {
    setPage(0)
  }, [search])
  return (
    <div className='row px-2'>
 <div className="col-md-12 data_table p-2 pb-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between searchbar pb-2 pt-3 px-md-3 my-auto">
                      <div className="d-flex left my-auto mb-2 mb-md-0 ps-3 ps-md-0">
                        <h6>All Admins</h6>
                      </div>
                      <div className="d-flex right my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 search_icon_svg">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Seacrh..' />
                        <button className='add_btn btn ms-1' onClick={() => handleClickOpenNew()}>Add</button>
                      </div>
                    </div>
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table" className='text-center'>
                        <TableHead>
                          <TableRow>
                            <TableCell className='th text-center'>SN</TableCell>
                            <TableCell className='th text-center'>Email</TableCell>
                            <TableCell className='th text-center'>Role</TableCell>
                            <TableCell className='th text-center'>Status</TableCell>
                            <TableCell className='th text-center'>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredAdmins && filteredAdmins.length > 0 ? filteredAdmins
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((admin, index) => (
                              <TableRow key={admin._id}>
                                <TableCell className='text-center td'>{index + 1}</TableCell>
                                <TableCell className='text-center td'>{admin.email}</TableCell>
                                <TableCell className='text-center td'>{admin.role}</TableCell>
                                <TableCell className='td text-center'>{admin.isActive ? <span className="active_span px-2 py-1">Active</span> : <span className="inactive_span px-2 py-1">Inactive</span>}</TableCell>
                                <TableCell className='td text-center'>
                                  <div class="btn-group" role="group" aria-label="Basic example">
                                    <button className="edit_btn py-1 px-2  m-1" onClick={()=>handleEditView(admin)}>Edit</button>
                                    <button onClick={() => handleClickOpen(admin)} className={admin.isActive ? "inactivate_shops py-1 px-2 m-1" : "activate_shops py-1 px-2 m-1"}>{admin.isActive ? "Inactivate" : "Activate"}</button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )) :
                            <TableRow className='border-0'>
                              <TableCell colSpan={2}></TableCell>
                              <TableCell className='td text-center'>No result</TableCell>
                              <TableCell colSpan={2}></TableCell>
                            </TableRow>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      component='div'
                      count={filteredAdmins && filteredAdmins.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      style={{
                        color: '#5932EA',
                        fontSize: '12px',
                        fontWeight: '700',
                        textTransform: 'capitalize',
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                   

 <Dialog className='p-0'
        open={openNew}
        fullWidth
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12  pb-2 px-0 m-0">
              <form onSubmit={editedAdmin?updateAdmin:addNewAdmin}>
                <div className="card add_new_package_card">
                  <div className="card-header">
                    {editedAdmin ? "Edit Admin" : "Add a new Admin Role"}
                    </div>
                  <div className="card-body">
                  
                    <div className="data my-1">
                      <label htmlFor="" >Email:</label>
                      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='mt-1' id="" required />
                    </div>
                  
                    <div className="data my-1">
                      <label htmlFor="">Select Role:</label>
                      <select name="" id="" value={role} onChange={(e) => setRole(e.target.value)} className='mt-1' required>
                        <option value="">Choose Role</option>
                        <option value="Marketing">Marketing</option>
                        
                      </select>
                      
                    </div>
                    <div className="data my-1">
                      <label htmlFor="">Password</label>
                      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='mt-1' id="" required={!editedAdmin} />
                    </div>
                    
                  <div className="data my-1">
                    <label htmlFor="is-active">Account Activation:</label>
                    <select
                      id="is-active"
                      required
                      value={isActive}
                      onChange={handleSelectChange(setIsActive)}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                    <div className="text-end mt-3">
                      <button className="btn cancel_btn mx-1" type='button' onClick={() => handleCloseNew()} disabled={loading1}>Cancel</button>
                      <button className="btn save_btn mx-1" type='submit' disabled={loading1}>{loading1 ? "Saving" : "Save"}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>

        {/* Activate/Inactivate Modal */}
        <Dialog className='shops_modal p-0'
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="col-md-12 text-center pb-2 px-0 m-0">
              <div className="alert_icon text-center">
                <div className={selectedAdmin && !selectedAdmin.isActive ? "activate_icon  py-2" : "inactivate_icon  py-2"}>
                  {selectedAdmin && !selectedAdmin.isActive ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 ">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                  }
                </div>
                <div className="content px-4">
                  <h6 className='mt-3'>Alert !</h6>
                  <p>Do you want to {selectedAdmin && !selectedAdmin.isActive ? "Activate" : "Inactivate"} {selectedAdmin && selectedAdmin.role} Account?</p>
                </div>
              </div>
              <button className="cancel m-1 py-1 px-2" disabled={updateLoading} onClick={handleClose}>Cancel</button>
              <button className={selectedAdmin && !selectedAdmin.isActive ? "activate m-1 py-1 px-2" : "inactivate m-1 py-1 px-2"} disabled={updateLoading} onClick={updateAdminStatus}>{updateLoading ? "Saving" : selectedAdmin && !selectedAdmin.isActive ? "Activate" : "Inactivate"}</button>
            </div>
          </div>
        </div>

      </Dialog>
    </div>
  )
}
