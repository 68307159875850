import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orders: [],
    totalorders: [],

};

export const ordersSlice = createSlice({
    name: 'ordersSlice',
    initialState,
    reducers: {
      
        getAllOrders: (state, action) => {
            state.orders = action.payload;
        },
        getTotalOrdersLength: (state, action) => {
            state.totalorders = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { getAllOrders,getTotalOrdersLength } = ordersSlice.actions;

export default ordersSlice.reducer;
